<template>
  <UModal v-model="isOpen">
    <div class="p-4 lg:pt-20 lg:pb-16 flex justify-center flex-col items-center text-center">
      <IconsThumbUp />

      <LayoutContentHeader :text="$t('modalDonationSuccess.title', { name: user.firstname })" />

      <LayoutText :text="$t('modalDonationSuccess.text')" />

      <UButton
        class="mt-6 mx-auto"
        :label="$t('btnContinue')"
        @click="isOpen = false"
      />
    </div>
  </UModal>
</template>

<script setup lang="ts">
const isOpen = ref(false)
const user = useUserStore()
const { track } = useTracker()

onMounted(async () => {
  if (useRoute().query['thanks-for-donating'] !== undefined) {
    isOpen.value = true

    const user = useUserStore()

    navigateTo(useRoute().path, { replace: true })

    const { event_time_meta, event_id } = getEventID()

    track('donate_success')
    await $fetch('/api/donate-success', {
      method: 'POST',
      body: {
        user_id: user.user_id,
        firstname: user.firstname,
        lastname: user.lastname,
        email: user.email,
        phone: user.phone,
        amount: user.last_donation?.amount,
        isRecurring: user.last_donation?.recurring,
        utm_donate: user.currentUTM,
        event_time_meta: event_time_meta,
        event_id: event_id,
      },
    })
  }
})
</script>
