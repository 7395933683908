import { v4 as uuidv4 } from 'uuid'

// Get ID to deduplicate Meta Pixel and Conversion API events
export default function (): {
  event_time_meta: number
  timestamp_sf: string
  event_id: string
} {
  const useTimestamp = (): string => {
    const utc = new Date()
    const timeZone = 'Europe/Oslo'

    const date = new Intl.DateTimeFormat('en-CA', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      timeZone: timeZone,
    }).format(utc)
    const time = new Intl.DateTimeFormat('no', {
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      timeZone: timeZone,
      timeZoneName: 'longOffset',
    }).format(utc)

    return `${date} ${time}` // Format: 2023-04-04 10:03:33+0100
  }

  return {
    timestamp_sf: useTimestamp(),
    event_time_meta: Math.floor(new Date().valueOf() / 1000),
    event_id: uuidv4(),
  }
}
