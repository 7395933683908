<script setup lang="ts">
import type { NuxtError } from '#app'

defineProps({
  error: Object as () => NuxtError,
})

const handleError = () => clearError({ redirect: '/' })

const { email } = getData().branding
</script>

<template>
  <LayoutContainer v-if="error">
    <LayoutHeader
      class="text-center"
      :text="`Error ${error.statusCode}`"
    />
    <div class="my-10 flex flex-col justify-center items-center">
      <LayoutText class="text-center mb-10">
        {{ error.statusMessage }} <br><br> Please report this error to the page administrator at {{ email }}
      </LayoutText>
      <UButton
        label="Go to frontpage"
        @click="handleError"
      />
    </div>
  </LayoutContainer>
</template>

<!--
https://nuxt.com/docs/guide/directory-structure/error
{
  statusCode: number
  fatal: boolean
  unhandled: boolean
  statusMessage?: string
  data?: unknown
  cause?: unknown
}
-->
