import { default as claims6jtULaoyv8Meta } from "/opt/buildhome/repo/pages/claims.vue?macro=true";
import { default as donateqgN5qA8tJkMeta } from "/opt/buildhome/repo/pages/donate.vue?macro=true";
import { default as feedbackh5np5t8gXaMeta } from "/opt/buildhome/repo/pages/feedback.vue?macro=true";
import { default as indexniDiYCWjuTMeta } from "/opt/buildhome/repo/pages/index.vue?macro=true";
import { default as opinionW2Nere1olbMeta } from "/opt/buildhome/repo/pages/opinion.vue?macro=true";
import { default as questions36KIlMkMNbMeta } from "/opt/buildhome/repo/pages/questions.vue?macro=true";
import { default as returnDDNCpCjRPOMeta } from "/opt/buildhome/repo/pages/return.vue?macro=true";
import { default as shareMybeKiZ1OIMeta } from "/opt/buildhome/repo/pages/share.vue?macro=true";
import { default as should_45confirm_45emailXByqlEWVuXMeta } from "/opt/buildhome/repo/pages/should-confirm-email.vue?macro=true";
import { default as signiJHOryBuQVMeta } from "/opt/buildhome/repo/pages/sign.vue?macro=true";
import { default as ssn0zSDkBpG6EMeta } from "/opt/buildhome/repo/pages/ssn.vue?macro=true";
import { default as thanksuS2yC6uj9dMeta } from "/opt/buildhome/repo/pages/thanks.vue?macro=true";
import { default as unsubscribedxd6b4FpePlMeta } from "/opt/buildhome/repo/pages/unsubscribed.vue?macro=true";
import { default as writepkYVVTWiz4Meta } from "/opt/buildhome/repo/pages/write.vue?macro=true";
export default [
  {
    name: "claims",
    path: "/claims",
    component: () => import("/opt/buildhome/repo/pages/claims.vue")
  },
  {
    name: "donate",
    path: "/donate",
    component: () => import("/opt/buildhome/repo/pages/donate.vue")
  },
  {
    name: "feedback",
    path: "/feedback",
    component: () => import("/opt/buildhome/repo/pages/feedback.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/opt/buildhome/repo/pages/index.vue")
  },
  {
    name: "opinion",
    path: "/opinion",
    component: () => import("/opt/buildhome/repo/pages/opinion.vue")
  },
  {
    name: "questions",
    path: "/questions",
    component: () => import("/opt/buildhome/repo/pages/questions.vue")
  },
  {
    name: "return",
    path: "/return",
    component: () => import("/opt/buildhome/repo/pages/return.vue")
  },
  {
    name: "share",
    path: "/share",
    component: () => import("/opt/buildhome/repo/pages/share.vue")
  },
  {
    name: "should-confirm-email",
    path: "/should-confirm-email",
    component: () => import("/opt/buildhome/repo/pages/should-confirm-email.vue")
  },
  {
    name: "sign",
    path: "/sign",
    component: () => import("/opt/buildhome/repo/pages/sign.vue")
  },
  {
    name: "ssn",
    path: "/ssn",
    component: () => import("/opt/buildhome/repo/pages/ssn.vue")
  },
  {
    name: "thanks",
    path: "/thanks",
    component: () => import("/opt/buildhome/repo/pages/thanks.vue")
  },
  {
    name: "unsubscribed",
    path: "/unsubscribed",
    component: () => import("/opt/buildhome/repo/pages/unsubscribed.vue")
  },
  {
    name: "write",
    path: "/write",
    component: () => import("/opt/buildhome/repo/pages/write.vue")
  }
]