import revive_payload_client_f57c1kPgLc from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_@types+node@22.7.4_eslint@9.11.1_rollup@4.24.0_typescript@5.6.2_vite@5.4.8_vue-tsc@2.1.6/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_TV8xoNnfqW from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_@types+node@22.7.4_eslint@9.11.1_rollup@4.24.0_typescript@5.6.2_vite@5.4.8_vue-tsc@2.1.6/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_CvqolQonna from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_@types+node@22.7.4_eslint@9.11.1_rollup@4.24.0_typescript@5.6.2_vite@5.4.8_vue-tsc@2.1.6/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_0ZV9ics2rZ from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_@types+node@22.7.4_eslint@9.11.1_rollup@4.24.0_typescript@5.6.2_vite@5.4.8_vue-tsc@2.1.6/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_aHiDk8Ce4n from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_@types+node@22.7.4_eslint@9.11.1_rollup@4.24.0_typescript@5.6.2_vite@5.4.8_vue-tsc@2.1.6/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_Qu6ZSqEqts from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_@types+node@22.7.4_eslint@9.11.1_rollup@4.24.0_typescript@5.6.2_vite@5.4.8_vue-tsc@2.1.6/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UXRaOByBAS from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_@types+node@22.7.4_eslint@9.11.1_rollup@4.24.0_typescript@5.6.2_vite@5.4.8_vue-tsc@2.1.6/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_3Giimm7PCq from "/opt/buildhome/repo/node_modules/.pnpm/@pinia+nuxt@0.5.5_rollup@4.24.0_typescript@5.6.2_vue@3.5.11/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/opt/buildhome/repo/.nuxt/components.plugin.mjs";
import prefetch_client_DGjbr4JGdK from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_@types+node@22.7.4_eslint@9.11.1_rollup@4.24.0_typescript@5.6.2_vite@5.4.8_vue-tsc@2.1.6/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import switch_locale_path_ssr_uQxXoWhtBq from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_rollup@4.24.0_vue@3.5.11/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_0T4Bi5RaJ6 from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_rollup@4.24.0_vue@3.5.11/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import slideovers_339XRYu8Ql from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui@2.18.6_rollup@4.24.0_vite@5.4.8_vue@3.5.11/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_Y8lxmiVH0y from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui@2.18.6_rollup@4.24.0_vite@5.4.8_vue@3.5.11/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_08H59YAmCQ from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui@2.18.6_rollup@4.24.0_vite@5.4.8_vue@3.5.11/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_rz3gPkYs9p from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+color-mode@3.5.1_rollup@4.24.0/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_CBizJX0uC9 from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+icon@1.5.2_rollup@4.24.0_vite@5.4.8_vue@3.5.11/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import plugin_IxSNCaBEU4 from "/opt/buildhome/repo/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.1_@pinia+nuxt@0.5.5_pinia@2.2.4_rollup@4.24.0/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
export default [
  revive_payload_client_f57c1kPgLc,
  unhead_TV8xoNnfqW,
  router_CvqolQonna,
  payload_client_0ZV9ics2rZ,
  navigation_repaint_client_aHiDk8Ce4n,
  check_outdated_build_client_Qu6ZSqEqts,
  chunk_reload_client_UXRaOByBAS,
  plugin_vue3_3Giimm7PCq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_DGjbr4JGdK,
  switch_locale_path_ssr_uQxXoWhtBq,
  i18n_0T4Bi5RaJ6,
  slideovers_339XRYu8Ql,
  modals_Y8lxmiVH0y,
  colors_08H59YAmCQ,
  plugin_client_rz3gPkYs9p,
  plugin_CBizJX0uC9,
  plugin_IxSNCaBEU4
]