import type { SnapchatPixelFunction, TiktokPixelFunction } from '~/types'
import { anyToSnapEvent, anyToTiktokEvent } from '~/types'

declare global {
  interface Window {
    snaptr: SnapchatPixelFunction
    ttq: TiktokPixelFunction
  }
}

// googleTagManager: useScriptGoogleTagManager({
//   id: google.tag_manager_id!,
//   scriptOptions: {
//     bundle: true,
//     trigger: consentAdvertising,
//   },
// }),
// redditPixel: useScript({
//   key: 'reddit-pixel',
//   src: '',
//   async: true,
//   defer: true,
// }, {
//      // TODO: fix
//   use() { return { reddit: window.reddit } },
//   trigger: consentAdvertising,
//   bundle: true,
// }),

export default function () {
  const cookies = useCookieStore()
  const { meta, google, snapchat, tiktok, clarity } = getData()
  const { enableScripts } = useRuntimeConfig().public
  const consentAdvertising = useScriptTriggerConsent({ consent: cookies.enableAdvertising })
  const consentAnalytics = useScriptTriggerConsent({ consent: cookies.enableAnalytics })

  const scripts = {
    // Analytics
    google: useScriptGoogleAnalytics({
      id: google.analytics_id!,
      scriptOptions: {
        bundle: true,
        trigger: consentAnalytics,
      },
    }),
    clarity: useScriptClarity({
      id: clarity.pixel_id!,
      scriptOptions: {
        bundle: false,
        trigger: consentAnalytics,
      },
    }),
    // Advertising
    meta: useScriptMetaPixel({
      id: meta.pixel_id,
      scriptOptions: {
        bundle: true,
        trigger: consentAdvertising,
      },
    }),
    // https://businesshelp.snapchat.com/s/article/pixel-direct-implementation
    snapchat: useScript({
      key: 'snapchat-pixel',
      src: 'https://sc-static.net/scevent.min.js',
      async: true,
      defer: true,
    }, {
      use() { return { snaptr: window.snaptr } },
      trigger: consentAdvertising,
      bundle: true,
    }),
    tiktok: useScript({
      key: 'tiktok-pixel',
      src: `https://analytics.tiktok.com/i18n/pixel/events.js?sdkid=${tiktok.pixel_id}&lib=ttq`,
      // crossorigin: false,
      async: true,
      defer: true,
      innerHTML: import.meta.server
        ? undefined
        : () => {
            // @ts-expect-error untyped
            window.TiktokAnalyticsObject = 'ttq'
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const ttq: any = (window.ttq = window.ttq || [])

            ttq.methods = [
              'page',
              'track',
              'identify',
              'instances',
              'debug',
              'on',
              'off',
              'once',
              'ready',
              'alias',
              'group',
              'enableCookie',
              'disableCookie',
              'holdConsent',
              'revokeConsent',
              'grantConsent',
            ]

            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            ttq.setAndDefer = function (obj: any[][], method: string | number) {
              // @ts-expect-error untyped
              obj[method] = function () {
                // eslint-disable-next-line prefer-rest-params
                obj.push([method].concat(Array.prototype.slice.call(arguments)))
              }
            }

            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            ttq.methods.forEach((method: any) => ttq.setAndDefer(ttq, method))

            ttq.instance = function (name: string | number) {
              const instance = ttq._i[name] || []
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              ttq.methods.forEach((method: any) => ttq.setAndDefer(instance, method))
              return instance
            }

            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            ttq.load = function (sdkId: string | number, options: any) {
              ttq._i = ttq._i || {}
              ttq._i[sdkId] = []
              ttq._i[sdkId]._u = 'https://analytics.tiktok.com/i18n/pixel/events.js'
              ttq._t = ttq._t || {}
              ttq._t[sdkId] = +new Date()
              ttq._o = ttq._o || {}
              ttq._o[sdkId] = options || {}
            }
          },
    }, {
      use() { return { ttq: window.ttq } },
      trigger: consentAdvertising,
      bundle: true,
    }),
  }

  const track = async (
    event_name: string,
    event_id?: string,
    customData: { value?: number, currency?: string } = {},
    server_side: boolean = false,
    event_time_meta?: number,
  ) => {
    console.log('track:', event_name, JSON.stringify(customData), event_id)
    if (!enableScripts) {
      console.log('Tracking is disabled.')
      return
    }

    const user = useUserStore()

    // @ts-expect-error
    scripts.google.proxy.gtag('event', event_name)
    scripts.meta.proxy.fbq('trackCustom', event_name, customData, { eventID: event_id })
    scripts.clarity.proxy.clarity('event', event_name)
    // scripts.tiktokPixel.proxy.ttq.track(event_name, {}, { event_id })
    if ('ttq' in window && event_name in anyToTiktokEvent) window.ttq.track(anyToTiktokEvent[event_name], {}, { event_id })

    // Snapchat
    if (anyToSnapEvent[event_name]) {
      // @ts-expect-error
      scripts.snapchat.proxy.snaptr('track', anyToSnapEvent[event_name], {
        uuid_c1: event_id,
        user_email: user.email,
        user_phone_number: user.phone,
        firstname: user.firstname,
        lastname: user.lastname,
      })
    }

    const { meta } = getData()

    if (server_side && meta.server_side) {
      const user = useUserStore()

      await $fetch('/api/event', {
        method: 'POST', body: {
          event_name: event_name,
          data: {
            user_id: user.user_id,
            firstname: user.firstname,
            lastname: user.lastname,
            email: user.email,
            phone: user.phone,
            event_id: event_id,
            event_time_meta: event_time_meta,
          },
        } })
    }
  }

  const pageView = () => {
    useScriptEventPage(() => {
      // @ts-expect-error
      scripts.snapchat.proxy.snaptr('track', 'PAGE_VIEW')
      // scripts.tiktok.proxy.ttq.page()
      if ('ttq' in window) window.ttq.page()
    })
  }

  const init = () => {
    const { preferences: { advertising, analytics } } = useCookieStore()
    scripts.snapchat.onLoaded(() => scripts.snapchat.proxy.snaptr('init', snapchat.pixel_id!))
    // scripts.tiktok.onLoaded(() => scripts.tiktok.proxy.ttq.load(tiktok.pixel_id!))
    scripts.tiktok.onLoaded(() => {
      if ('ttq' in window) window.ttq.load(tiktok.pixel_id!)
    })
    // @ts-expect-error
    scripts.google.proxy.gtag('consent', 'update', {
      ad_storage: advertising ? 'denied' : 'granted',
      ad_user_data: advertising ? 'denied' : 'granted',
      ad_personalization: advertising ? 'denied' : 'granted',
      analytics_storage: analytics ? 'denied' : 'granted',
    })
  }

  const advancedMatching = (
    em?: string,
    fn?: string,
    ln?: string,
    ph?: string,
    external_id?: string,
  ) => {
    scripts.meta.fbq('init', meta.pixel_id, {
      em,
      fn,
      ln,
      ph,
      external_id,
    })

    scripts.tiktok.proxy.ttq.identify({
      email: em,
      phone_number: ph,
      external_id: external_id,
    })

    console.log('AdvancedMatching set')
  }

  return {
    track,
    pageView,
    init,
    advancedMatching,
  }
}
