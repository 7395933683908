/**
 * @interface MauticQuery
 * @search String or search command to filter entities by.
 * @start Starting row for the entities returned. Defaults to 0.
 * @limit Limit number of entities to return. Defaults to the system configuration for pagination (30).
 * @orderBy Column to sort by. Can use any column listed in the response. However, all properties in the response that are written in camelCase need to be changed a bit. Before every capital add an underscore _ and then change the capital letters to non-capital letters. So dateIdentified becomes date_identified, modifiedByUser becomes modified_by_user etc.
 * @orderByDir Sort direction: asc or desc.
 * @publishedOnly Only return currently published entities.
 * @minimal Return only array of entities without additional lists in it.
 * @where An array of advanced where conditions
 * @order An array of advanced order statements
 */

// import type { MauticSchema } from "~/utils/schemas";
import z from 'zod'

// https://developer.mautic.org/#list-contacts
export interface MauticContactExtract {
  [key: string]: number | string | undefined | boolean | null
  user_id: number
  dateAdded: string
  firstname: string | null
  lastname: string | null
  email: string
  opinion: MauticOpinionOption
  is_recurring_donor: boolean
  email_confirmed: boolean
  finished_writing: boolean
  finished_sharing: boolean
  finished_material: boolean
  finished_feedback: boolean
  coop_member: string
  active_subscriber: boolean
  utm_entry?: string
}

export interface MauticQuery {
  limit?: number
  start?: number
  orderBy?: string
  orderByDir?: 'asc' | 'desc'
  publishedOnly?: string
  search?: string
  minimal?: boolean
  where?: string // not sure
  order?: string // not sure
}

export const mauticOpinionOptions = ['no_buy', 'reduce', 'own_way'] as const
export type MauticOpinionOption = (typeof mauticOpinionOptions)[number]

export const mauticCoopMemberOptions = ['yes', 'no', 'not_sure', 'will_not_say'] as const
export type MauticCoopMemberOptions = (typeof mauticOpinionOptions)[number]

// const mauticBoolean = z.number().refine((val) => val === 0 || val === 1);

export const mauticContactSchema = z.object({
  firstname: z.string().min(1),
  lastname: z.string().min(1),
  email: z.string().min(1),
  phone: z.string().min(1),
  points: z.string().min(1),
  opinion: z.enum(mauticOpinionOptions),
  email_confirmed: z.boolean(),
  finished_writing: z.boolean(),
  finished_sharing: z.boolean(),
  finished_feedback: z.boolean(),
  finished_material: z.boolean(),
  active_subscriber: z.boolean(),
  coop_member: z.enum(mauticCoopMemberOptions),
  a_b_test: z.string(),
  utm_entry: z.string().optional(),

  // Only to be updated, not stored
  utm_sign: z.string().optional(),
  utm_share: z.string().optional(),
  utm_write: z.string().optional(),
  utm_donate: z.string().optional(),
  utm_donor: z.string().optional(),
})

export type MauticContactSchema = z.output<typeof mauticContactSchema>

export type MauticFieldNames = keyof MauticContactSchema

export interface MauticContactResponse extends MauticContactSchema {
  user_id: number
  dateAdded: string
}

export interface MauticSegment {
  id: number
  isPublished: number
  dateAdded: string
  createdBy: number
  createdByUser: string
  dateModified: string
  modifiedBy: number
  modifiedByUser: string
  category: null | string
  name: string
  alias: string
  description: string
  filters: [
    {
      glue: string
      field: string
      type: string
      filter: string
      display: null | string
      operator: string
    },
  ]
  isGlobal: boolean
}

export interface MauticTagsUTM {
  utm_source: string
  utm_medium: string
  utm_campaign: string
  utm_content: string
  useragent: string
  url: string
  referer: string
  query: string
  remotehost: string
  lastActive: string
}

export interface MauticContact {
  isPublished: boolean
  dateAdded: string
  dateModified: string
  createdBy: null
  createdByUser: null | string
  modifiedBy: null | string
  modifiedByUser: string
  id: number
  points: number
  color: null | string
  tags: {
    id: number
    tag: string
  }[]
  utmtags: {
    id: number
    query: {
      p: string
      id: string
      ct: {
        source: string[]
        email: string
        stat: string
        lead: string
        channel: { email: string }
      }
      utm_source: string
      utm_medium: string
      utm_campaign: string
      utm_content: string
      page_url: string
      referer: string
      remoteHost: string | null
      url: string
      userAgent: string
      utmSource: string
      utmCampaign: string
      utmContent?: string
      utmMedium?: string
      utmTerm?: string
    }
  }[]
  stage: {
    category: { createdByUser: string, modifiedByUser: null | string }
    createdByUser: string
    description: null | string
    id: number
    modifiedByUser: null | string
    name: string
    weight: number
  }
  fields: {
    all: MauticContactResponse
  }
}

// export interface MauticField {
//   id: number;
//   label: string;
//   alias: string;
//   type: string;
//   group: string;
//   object: string;
//   is_fixed: string;
//   properties: string;
//   default_value: string;
//   value: string;
//   normalizedValue: string;
// }

export interface MauticMultiResponse {
  total: string
  contacts: {
    [key: string]: MauticContact
  }
}

export interface MauticSingleResponse {
  contact: MauticContact
}
