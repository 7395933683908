// https://prazdevs.github.io/pinia-plugin-persistedstate/frameworks/nuxt-3.html
import { defineStore } from 'pinia'

export const useCookieStore = defineStore(
  'cookies',
  () => {
    interface CookiePreferences {
      analytics: boolean
      advertising: boolean
      essential: boolean
    }

    const { enableCookieBanner } = getData()
    const { defaultConsent } = useRuntimeConfig().public

    const showCookieBanner = ref<boolean>(enableCookieBanner || true)
    const consent = ref<boolean>(defaultConsent || false)
    const preferences = ref<CookiePreferences>({
      analytics: true,
      advertising: true,
      essential: true,
    })

    const enableAnalytics = computed(() => consent.value && preferences.value.analytics)
    const enableAdvertising = computed(() => consent.value && preferences.value.advertising)

    function allow(allowAll: boolean = false) {
      consent.value = true
      showCookieBanner.value = false
      if (allowAll) {
        preferences.value.advertising = true
        preferences.value.analytics = true
      }
      console.log('User consents to cookies/ tracking.')
    }

    function disallow() {
      consent.value = false
      showCookieBanner.value = false
      console.log('User does not consent to cookies/ tracking.')
    }

    return {
      // Refs?
      consent,
      preferences,
      showCookieBanner,

      // Getters
      enableAnalytics,
      enableAdvertising,

      // Mutations
      allow,
      disallow,
    }
  },
  { persist: true },
)
