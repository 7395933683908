export type TiktokEvents = 'InitiateCheckout' | 'PlaceAnOrder' | 'ClickForm' | 'SubmitForm' | 'ClickInConsultationPage' | 'ClickButton' | 'ClickInDownloadPage' | 'ClickToDownload' | 'Download' | 'Consult' | 'Contact' | 'ConsultByPhone' | 'Contact' | 'Purchase' | 'CompletePayment' | 'Registration' | 'CompleteRegistration' | 'ViewConsultationPage' | 'ViewContent' | 'ViewDownloadPage' | 'ViewForm'

export interface TiktokPixelFunction {
  // "page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie","holdConsent","revokeConsent","grantConsent"
  load: (pixelId: string) => void
  page: () => void
  track: (eventName: string, options?: { contents?: string[], content_Type?: string, value?: number, currency?: string }, event_id?: { event_id?: string }) => void
  identify: (data: { email?: string, phone_number?: string, external_id?: string }) => void
}

export const anyToTiktokEvent: { [key: string]: TiktokEvents } = {
  petition_signed: 'SubmitForm',
  email_confirmed: 'CompleteRegistration',
  donate_initiated: 'InitiateCheckout',
  donate_success: 'CompletePayment',
  share_finished: 'Contact',
  // write_finished: '',
  // opinion_finished: '',
  // questions_finished: '',
  // feedback_finished: '',
}
