<template>
  <UCard
    v-if="cookies.showCookieBanner"
    :ui="{
      base: 'z-30 fixed max-w-[500px] bottom-0 right-0 max-md:m-1 md:m-6',
      background: '',
      rounded: '',
      shadow: 'shadow',
      header: {
        base: 'flex justify-between items-center font-semibold text-lg rounded-t',
        background: 'bg-white',
        padding: 'px-4 pt-4',
      },
      body: {
        base: 'flex flex-col gap-6',
        background: 'bg-white',
        padding: 'px-4 pt-2 pb-4',
      },
      footer: {
        base: 'text-black text-sm rounded-b gap-4 flex flex-row',
        background: 'bg-gray-100',
        padding: 'px-4 py-2',
      },
    }"
  >
    <UModal
      v-model="isOpenPreferences"
      :ui="{
        width: 'w-full max-w-[600px]',
      }"
    >
      <ConsentManagementPreferences @close="isOpenPreferences = false" />
    </UModal>
    <template #header>
      <p v-text="$t('modalCookieConsent.title')" />
    </template>
    <p
      class="md:text-sm max-md:text-xs break-words hyphens-auto"
      v-text="$t('modalCookieConsent.text')"
    />
    <UButtonGroup
      size="base"
      class="w-full"
    >
      <UButton
        color="white"
        class="w-full"
        :label="$t('modalCookieConsent.btnPreferences')"
        @click="isOpenPreferences = true"
      />
      <UButton
        color="white"
        class="w-full"
        :label="$t('modalCookieConsent.btnDeclineAll')"
        @click="cookies.disallow()"
      />
      <UButton
        class="w-full"
        :label="$t('modalCookieConsent.btnAccept')"
        @click="cookies.allow(true)"
      />
    </UButtonGroup>
    <template #footer>
      <ModalPrivacyPolicy btn-class="!no-underline hover:!underline" />
      <ModalSalesTerms btn-class="!no-underline hover:!underline" />
    </template>
  </UCard>
</template>

<script setup lang="ts">
const cookies = useCookieStore()

const isOpen = ref(false)
const isOpenPreferences = ref(false)
const consent = computed(() => cookies.consent)
watch(consent, () => {
  if (consent.value === true) useTracker().init()
})

onMounted(() => {
  if (cookies.showCookieBanner) {
    isOpen.value = true
    return
  }
})
</script>
